import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Home from "./Components/Home";
import MouseTracer from "./Components/MouseTracer";

import ContactME from "./Components/Contact/ContactME";
import Navbar from "./Components/NavBar";
import ProjectsPage from "./Components/Projects/Project";
import ResumePage from "./Components/Resume/Resume";
import ThreeScene from "./Components/ThreeJSBg";
import WorkExperience from "./Components/WorkExperience/WorkExperience";
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};

const AppContent = () => {
  const location = useLocation();
  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      {/* ThreeScene as the fixed background */}
      <ThreeScene />

      {/* Main contentt */}
      <div>
        {location.pathname !== "/resume" && <Navbar />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resume" element={<ResumePage />} />
          <Route path="/workExperience" element={<WorkExperience />} />
          <Route path="/contact" element={<ContactME />} />
          <Route path="/projects" element={<ProjectsPage />} />
        </Routes>
        {!isTouchDevice() && <MouseTracer />}
      </div>
    </div>
  );
};

export default App;
