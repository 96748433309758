import React, { useEffect, useRef } from "react";
import * as THREE from "three";
// import Stats from "three/examples/jsm/libs/stats.module";

const ThreeScene = () => {
  const mountRef = useRef(null);
  //   const [stats, setStats] = useState(null);

  useEffect(() => {
    const container = mountRef.current;
    let mouseX = 0;
    let mouseY = 0;
    const windowHalfX = window.innerWidth / 2;
    const windowHalfY = window.innerHeight / 2;

    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      1,
      3000
    );
    camera.position.z = 1000;

    // Renderer setup
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    container.appendChild(renderer.domElement);

    // Stats
    // const stats = Stats();
    // stats.showPanel(0);
    // container.appendChild(stats.dom);
    // setStats(stats);

    // Geometry
    const geometry = new THREE.BufferGeometry();
    const particleCount = 50;
    const vertices = new Float32Array(particleCount * 3);

    for (let i = 0; i < particleCount; i++) {
      vertices[i * 3] = Math.random() * 2000 - 1000;
      vertices[i * 3 + 1] = Math.random() * 2000 - 1000;
      vertices[i * 3 + 2] = Math.random() * 2000 - 1000;
    }

    geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));

    // Material
    // const parameters = [
    //   [[1, 1, 0.5], 5],
    //   [[0.95, 1, 0.5], 4],
    //   [[0.9, 1, 0.5], 3],
    //   [[0.85, 1, 0.5], 2],
    //   [[0.8, 1, 0.5], 1],
    // ];
    const parameters = [
      [[0, 0, 1], 5], // White
      [[0, 0, 0.9], 4], // Off-White
      [[0, 0, 0.5], 3], // Grey
      [[0, 0, 0.2], 2], // Charcoal Grey
      [[0, 1, 0.5], 1], // Red
    ];

    const materials = [];
    parameters.forEach(([color, size], index) => {
      const material = new THREE.PointsMaterial({ size });
      materials.push(material);

      const particles = new THREE.Points(geometry, material);
      particles.rotation.x = Math.random() * 6;
      particles.rotation.y = Math.random() * 6;
      particles.rotation.z = Math.random() * 6;

      scene.add(particles);
    });

    // Resize event
    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener("resize", onWindowResize);

    // Mouse movement event
    const onDocumentMouseMove = (e) => {
      mouseX = e.clientX - windowHalfX;
      mouseY = e.clientY - windowHalfY;
    };
    document.addEventListener("mousemove", onDocumentMouseMove);

    const onDocumentTouchStart = (e) => {
      if (e.touches.length === 1) {
        // e.preventDefault();
        mouseX = e.touches[0].pageX - windowHalfX;
        mouseY = e.touches[0].pageY - windowHalfY;
      }
    };
    document.addEventListener("touchstart", onDocumentTouchStart, {
      passive: true,
    });

    const onDocumentTouchMove = (e) => {
      if (e.touches.length === 1) {
        // e.preventDefault();
        mouseX = e.touches[0].pageX - windowHalfX;
        mouseY = e.touches[0].pageY - windowHalfY;
      }
    };
    document.addEventListener("touchmove", onDocumentTouchMove, {
      passive: true,
    });

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      const time = Date.now() * 0.00005;

      camera.position.x += (mouseX - camera.position.x) * 0.05;
      camera.position.y += (-mouseY - camera.position.y) * 0.05;

      camera.lookAt(scene.position);

      scene.children.forEach((object, i) => {
        if (object instanceof THREE.Points) {
          object.rotation.y = time * (i < 4 ? i + 1 : -(i + 1));
        }
      });

      materials.forEach((material, i) => {
        const color = parameters[i][0];
        const h = ((360 * (color[0] + time)) % 360) / 360;
        material.color.setHSL(h, color[1], color[2]);
      });

      renderer.render(scene, camera);
      //   stats.update();
    };
    animate();

    // Cleanup
    return () => {
      window.removeEventListener("resize", onWindowResize);
      document.removeEventListener("mousemove", onDocumentMouseMove);
      document.removeEventListener("touchstart", onDocumentTouchStart);
      document.removeEventListener("touchmove", onDocumentTouchMove);
      container.removeChild(renderer.domElement);
      //   container.removeChild(stats.dom);
    };
  }, []);

  return (
    <div
      ref={mountRef}
      style={{
        margin: 0,
        overflow: "hidden",
        width: "100vw",
        height: "100vh",
        pointerEvents: "none",
      }}
      className="fixed top-0 left-0 w-full h-full z-0"
    />
  );
};

export default ThreeScene;
