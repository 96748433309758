import { useEffect } from "react";
import AboutMe from "./AboutMe/AboutMe";
function Home() {
  useEffect(() => {
    // Disable scrolling when the component is mounted
    // document.body.style.overflow = "hidden";
    return () => {
      // Re-enable scrolling when the component is unmounted
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    // make it placed in exact centre

    <div className=" flex justify-center items-center h-screen  ">
      {/* <Navbar />
      <ThreeScene /> */}
      <AboutMe />
      {/* <MouseTracer /> */}
    </div>
  );
}

export default Home;
