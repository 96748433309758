import { Building2, Calendar, ChevronDown, Code, MapPin } from "lucide-react";
import React, { useState } from "react";

const WorkExperience = () => {
  const [activeId, setActiveId] = useState(null);

  const experiences = [
    {
      id: 1,
      role: "Project Intern",
      company: "Tata Advanced Systems Limited",
      period: "June 2024 - Present",
      location: "Mumbai, India",
      achievements: [
        "Implementing shortest path algorithms for drone navigation",
        "Developing real-time object detection system",
        "Researching pattern and anomaly detection",
      ],
      skills: ["Python", "YOLO", "OpenCV", "Algorithms"],
    },
    {
      id: 2,
      role: "Software Engineering Intern",
      company: "Switch-ON Industries",
      period: "December 2024 - February 2025",
      location: "Remote",
      achievements: [
        "Developed the frontend for the Inventory Management Website using TypeScript and Svelte, and hosted the static build on AWS S3.",
        "Designed and built key interfaces for inventory tracking, order management, and transactions, streamlining operations for multiple roles.",
        "Designed and built interfaces for gala incharge, moulder, contractor, and vendor to manage product details, orders, and stock tracking.",
      ],
      skills: ["Svelte", "Typescript", "Tailwind", "Python", "Django"],
    },

    {
      id: 3,
      role: "Freelance Developer",
      company: "Switch-ON Industries",
      period: "June 2024 - October 2024",
      location: "Remote",
      achievements: [
        "Developing Desktop App for costing and billing",
        "Implemented auto-fill feature for raw materials",
        "Created PDF generation and material listing",
      ],
      skills: ["React", "Electron", "Redux", "Tailwind"],
    },
    {
      id: 4,
      role: "Software Development Intern",
      company: "Alpha Electronic Controls",
      period: "April 2024 - June 2024",
      location: "Mumbai, India",
      achievements: [
        "Built cross-platform mobile app for task management",
        "Implemented geo-fencing features",
        "Created project and task assignment interfaces",
      ],
      skills: ["React Native", "MySQL", "PHP"],
    },
  ];

  return (
    <div className="relative min-h-screen py-20 px-4 z-40">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-12 text-white">
          Work Experience
        </h1>

        <div className="space-y-6">
          {experiences.map((exp) => (
            <div
              key={exp.id}
              className="bg-white/10 backdrop-blur-lg border border-white/20 shadow-xl rounded-lg overflow-hidden hover:bg-white/20 transition-all duration-300"
            >
              <div
                className="p-6 flex justify-between items-center cursor-pointer"
                onClick={() => setActiveId(activeId === exp.id ? null : exp.id)}
              >
                <div>
                  <h2 className="text-2xl font-semibold text-white">
                    {exp.role}
                  </h2>
                  <div className="text-gray-300 mt-2 flex flex-wrap items-center gap-4">
                    <div className="flex items-center gap-2">
                      <Building2 className="w-4 h-4" />
                      <span>{exp.company}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Calendar className="w-4 h-4" />
                      <span>{exp.period}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <MapPin className="w-4 h-4" />
                      <span>{exp.location}</span>
                    </div>
                  </div>
                </div>
                <ChevronDown
                  className={`w-6 h-6 text-white transition-transform duration-300 ${
                    activeId === exp.id ? "rotate-180" : ""
                  }`}
                />
              </div>

              {activeId === exp.id && (
                <div className="p-6 border-t border-white/20">
                  <div className="mb-6">
                    <h3 className="text-xl font-semibold text-white mb-4 flex items-center">
                      <Code className="w-5 h-5 mr-2" />
                      Work
                    </h3>
                    <ul className="space-y-2 text-gray-300">
                      {exp.achievements.map((achievement, index) => (
                        <li key={index} className="flex items-start">
                          <span className="mr-2 text-blue-400">•</span>
                          {achievement}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h3 className="text-xl font-semibold text-white mb-4 flex items-center">
                      <Code className="w-5 h-5 mr-2" />
                      Technologies
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {exp.skills.map((skill) => (
                        <span
                          key={skill}
                          className="px-3 py-1 bg-blue-500/20 text-blue-300 border border-blue-500/30 rounded-full text-sm"
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;
