import emailjs from "@emailjs/browser";
import { Email, GitHub, LinkedIn } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";

function ContactME() {
  const form = useRef();
  const [status, setStatus] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    setStatus("sending");
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setStatus("success");
          form.current.reset();
        },
        (error) => {
          setStatus("error");
          console.log(error);
        }
      );
  };

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white/10 backdrop-blur-lg p-8 rounded-xl border border-white/20">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
            Get in Touch
          </h2>
          <p className="mt-2 text-center text-sm text-gray-300">
            Feel free to reach out for opportunities or just to say hi!
          </p>
        </div>

        <form ref={form} onSubmit={sendEmail} className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm space-y-4">
            <input
              type="hidden"
              id="name"
              name="to_name"
              value="Vivek Sharma"
            />
            <div>
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                type="email"
                id="from_email"
                name="from_email"
                required
                className="appearance-none relative block w-full px-3 py-2 border border-white/20 bg-white/5 text-white rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Your Email"
              />
            </div>
            <div>
              <label htmlFor="message" className="sr-only">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                required
                rows="4"
                className="appearance-none relative block w-full px-3 py-2 border border-white/20 bg-white/5 text-white rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Your Message"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                status === "sending"
                  ? "bg-blue-400 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-700"
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
              disabled={status === "sending"}
            >
              {status === "sending" ? "Sending..." : "Send Message"}
            </button>
          </div>

          {status === "success" && (
            <p className="text-green-400 text-center">
              Message sent successfully!
            </p>
          )}
          {status === "error" && (
            <p className="text-red-400 text-center">
              Failed to send message. Please try again.
            </p>
          )}
        </form>

        <div className="flex justify-center space-x-4 mt-8">
          <Tooltip title="Email">
            <IconButton
              href="mailto:vivekksharma369@gmail.com"
              target="_blank"
              className="text-white hover:text-blue-400"
            >
              <Email />
            </IconButton>
          </Tooltip>
          <Tooltip title="GitHub">
            <IconButton
              href="https://github.com/Viveksharma121"
              target="_blank"
              className="text-white hover:text-blue-400"
            >
              <GitHub />
            </IconButton>
          </Tooltip>
          <Tooltip title="LinkedIn">
            <IconButton
              href="https://www.linkedin.com/in/vivek-sharma-776b2424a/"
              target="_blank"
              className="text-white hover:text-blue-400"
            >
              <LinkedIn />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default ContactME;
