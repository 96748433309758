import React, { useEffect, useState } from "react";
import "./MouseTracer.css";

const MouseTracer = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [outerCirclePosition, setOuterCirclePosition] = useState({
    x: 0,
    y: 0,
  });

  const updateMousePosition = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    const moveOuterCircle = () => {
      setOuterCirclePosition((prevPos) => ({
        x: prevPos.x + (mousePosition.x - prevPos.x) * 0.1,
        y: prevPos.y + (mousePosition.y - prevPos.y) * 0.1,
      }));
    };

    const interval = setInterval(moveOuterCircle, 10);
    return () => clearInterval(interval);
  }, [mousePosition]);

  useEffect(() => {
    window.addEventListener("mousemove", updateMousePosition);

    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  return (
    <>
      {/* Outer circle - follows the inner circle with delay */}
      <div
        className="outer-circle"
        style={{
          left: `${outerCirclePosition.x}px`,
          top: `${outerCirclePosition.y}px`,
        }}
      ></div>

      {/* Inner circle - directly follows the mouse */}
      <div
        className="inner-circle"
        style={{
          left: `${mousePosition.x}px`,
          top: `${mousePosition.y}px`,
        }}
      ></div>
    </>
  );
};

export default MouseTracer;
