import { Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-transparent backdrop-blur-lg fixed w-full z-50 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center ml-2 sm:ml-0">
            <Typography
              variant="h5"
              component="h1"
              sx={{
                mb: 2,
                fontWeight: "bold",
                fontSize: "1.4rem",
                textAlign: "left",
                color: "white",
                fontStyle: "italic",
                textShadow: "0 0 8px rgba(255, 255, 255, 0.7)",
                cursor: "pointer",
              }}
              onClick={() => {
                if (window.location.pathname !== "/") {
                  window.location.href = "/";
                }
              }}
            >
              Vivek Sharma
            </Typography>
          </div>
          <div className="hidden md:flex items-center space-x-6">
            <Link
              to="/workExperience"
              className="text-white hover:text-yellow-300 transition duration-300"
            >
              Work Experience
            </Link>
            <Link
              to="/projects"
              className="text-white hover:text-yellow-300 transition duration-300"
            >
              Projects
            </Link>
            <Link
              to="/resume"
              className="text-white hover:text-yellow-300 transition duration-300"
            >
              Resume
            </Link>
            <Link
              to="/contact"
              className="text-white hover:text-yellow-300 transition duration-300"
            >
              Contact
            </Link>
          </div>
          {/* Mobile Menu */}
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu Dropdown */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-center bg-black/10">
            <Link
              to="/workExperience"
              className="text-white hover:text-yellow-300 block px-3 py-2 rounded-md text-base font-medium"
              onClick={handleLinkClick}
            >
              Work Experience
            </Link>
            <Link
              to="/projects"
              className="text-white hover:text-yellow-300 block px-3 py-2 rounded-md text-base font-medium"
              onClick={handleLinkClick}
            >
              Projects
            </Link>
            <Link
              to="/resume"
              className="text-white hover:text-yellow-300 block px-3 py-2 rounded-md text-base font-medium"
              onClick={handleLinkClick}
            >
              Resume
            </Link>
            <Link
              to="/contact"
              className="text-white hover:text-yellow-300 block px-3 py-2 rounded-md text-base font-medium"
              onClick={handleLinkClick}
            >
              Contact
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
