import { GitHub, Launch } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import "./Project.css";
const projectsData = [
  // {
  //   "id": 1,
  //   "title": "MentEase",
  //   "description": "A collaborative learning platform for sharing experiences and knowledge across tech domains, featuring a threads page, profile UI, and resource posts with a credit points system for engagement.",
  //   "tags": ["MongoDB", "Express.js", "React", "Node.js"],
  //   "image": "/api/placeholder/600/400",
  //   "github": "https://github.com/Viveksharma121/MentorMentee",
  //   "live": "N/A"
  // },
  {
    id: 1,
    title: "MentEase",
    description:
      "A collaborative learning platform for sharing experiences and knowledge across tech domains. Features include a credit points system to incentivize active participation and a real-time chat system using Socket.io. The backend is built with Express.js and MongoDB.",
    tags: ["MongoDB", "Express.js", "React Native", "Node.js", "Socket.io"],
    image: "/api/placeholder/600/400",
    github: "https://github.com/Viveksharma121/MentorMentee",
    live: "N/A",
  },
  {
    id: 2,
    title: "Student Marketplace App",
    description:
      "A user-centric platform designed for students to buy and sell textbooks and academic materials securely. Built with Flutter and Dart for a seamless cross-platform experience, and powered by Firebase for backend support. Features include user authentication, in-app chat, and a streamlined book listing process.",
    tags: [
      "Flutter",
      "Dart",
      "Firebase",
      "Firestore",
      "Firebase Authentication",
    ],
    image: "/api/placeholder/600/400",
    github: "https://github.com/Viveksharma121/student_marketplace",
    live: "N/A",
  },
  {
    id: 3,
    title: "ImpactConnect",
    description:
      "A cross-platform mobile app facilitating companies in meeting CSR mandates by connecting them with local NGOs, featuring real-time updates and an AI chatbot for CSR compliance guidance.",
    tags: ["React Native", "Node.js", "Express.js", "OpenAI API"],
    image: "/api/placeholder/600/400",
    github: "https://github.com/Viveksharma121/Hacks24-OK200",
    live: "N/A",
  },
  {
    id: 4,
    title: "QuillSync",
    description:
      "A collaborative document management system with authentication, real-time editing, link sharing, and access management. Features include a rich text editor built with React Quill, real-time collaboration via Socket.io, and document summarization using Gensim.",
    tags: ["React", "Socket.io", "MongoDB", "Gensim", "React Quill"],
    image: "/api/placeholder/600/400",
    github: "https://github.com/Viveksharma121/GoogleDocClone",
    live: "N/A",
  },
  {
    id: 5,
    title: "Carpooling Website",
    description:
      "A web application built with ReactJS to streamline ride-sharing. Features include user authentication, car addition, real-time car fetching, and an intelligent ride-suggestion algorithm using a weight-distance metric. Implemented a chat feature using Socket.io and developed the backend with Node.js and Express.js, using MongoDB for data management.",
    tags: ["React", "Node.js", "Express.js", "MongoDB", "Socket.io"],
    image: "/api/placeholder/600/400",
    github: "https://github.com/Viveksharma121/NFC_OK200",
    live: "N/A",
  },
];

const getProjectMedia = (project) => {
  if (project.videoUrl) {
    return (
      <iframe
        src={project.videoUrl}
        title={project.title}
        className="w-full h-full"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  }

  if (project.image && !project.image.includes("/api/placeholder/")) {
    return (
      <img
        src={project.image}
        alt={project.title}
        className="w-full h-full object-cover"
      />
    );
  }

  return (
    <div className="w-full h-full bg-gradient-to-br from-blue-900/40 to-purple-900/40 flex flex-col items-center justify-center p-4">
      <div className="text-3xl font-bold text-white/80 mb-2 text-center">
        {project.title}
      </div>

      <div className="flex flex-wrap justify-center gap-3 mt-2">
        {project.tags.slice(0, 4).map((tag) => {
          const techIcon = getTechIcon(tag);
          return (
            <span
              key={tag}
              className="bg-white/10 px-3 py-1 rounded-full text-blue-300 text-xs"
            >
              {techIcon} {tag}
            </span>
          );
        })}
      </div>
    </div>
  );
};

const getTechIcon = (tech) => {
  const techIcons = {
    React: "⚛️",
    "Node.js": "🟢",
    MongoDB: "🍃",
    "Express.js": "🚂",
    JavaScript: "𝐉𝐒",
    TypeScript: "𝐓𝐒",
    Python: "🐍",
    Flutter: "💙",
    Firebase: "🔥",
  };

  return techIcons[tech] || "🔧";
};

function ProjectsPage() {
  const [filter, setFilter] = useState("All");

  const allTags = [
    "All",
    ...new Set(projectsData.flatMap((project) => project.tags)),
  ];

  const filteredProjects =
    filter === "All"
      ? projectsData
      : projectsData.filter((project) => project.tags.includes(filter));

  return (
    <div className="min-h-screen py-16 px-4 sm:px-6 lg:px-8 relative">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-10 text-white">
          Projects
        </h1>

        {/* Filter Tags */}
        <div className="flex flex-wrap justify-center gap-2 mb-8">
          {allTags.map((tag) => (
            <button
              key={tag}
              onClick={() => setFilter(tag)}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                filter === tag
                  ? "bg-blue-600 text-white"
                  : "bg-white/10 text-gray-300 hover:bg-white/20"
              }`}
            >
              {tag}
            </button>
          ))}
        </div>

        {/* Scrollable Projects Container */}
        <div className="max-h-[70vh] overflow-y-auto pr-2 pb-4 custom-scrollbar">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredProjects.map((project) => (
              <div
                key={project.id}
                className="bg-white/10 backdrop-blur-lg rounded-lg overflow-hidden border border-white/20"
              >
                {/* Project Media (Video or Image) */}
                {/* <div className="relative h-52 overflow-hidden">
                  {project.videoUrl ? (
                    <iframe
                      src={project.videoUrl}
                      title={project.title}
                      className="w-full h-full"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  ) : (
                    <img
                      src={project.image}
                      alt={project.title}
                      className="w-full h-full object-cover"
                    />
                  )}
                </div> */}
                <div className="relative h-52 overflow-hidden">
                  {getProjectMedia(project)}
                </div>

                {/* Project Info */}
                <div className="p-5">
                  <h3 className="text-xl font-semibold text-white mb-2">
                    {project.title}
                  </h3>
                  <p className="text-gray-300 mb-4 text-sm">
                    {project.description}
                  </p>

                  {/* Tags */}
                  <div className="flex flex-wrap gap-2 mb-4">
                    {project.tags.map((tag) => (
                      <span
                        key={tag}
                        className="bg-white/5 border border-white/10 px-2 py-1 rounded-md text-xs text-blue-300"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>

                  {/* Action Links */}
                  <div className="flex justify-between items-center mt-4">
                    <Tooltip title="View Code">
                      <IconButton
                        href={project.github}
                        target="_blank"
                        className="text-white hover:text-blue-400"
                      >
                        <GitHub />
                      </IconButton>
                    </Tooltip>
                    {project.live === "N/A" ? null : (
                      <Tooltip title="Live Demo">
                        <IconButton
                          href={project.live}
                          target="_blank"
                          className="text-white hover:text-blue-400"
                        >
                          <Launch />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsPage;
