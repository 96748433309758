import { Description, Email, GitHub, LinkedIn } from "@mui/icons-material";

import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { SiLeetcode } from "react-icons/si";
import { useNavigate } from "react-router-dom";
const AboutMe = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      className="flex justify-center items-center py-20 z-40"
      sx={{
        background: "transparent",
        color: "white",
        textAlign: "center",
        fontFamily: '"Roboto Mono", monospace',
        letterSpacing: "0.5px",
      }}
    >
      <Box
        sx={{
          p: 4,
          maxWidth: "800px",
          width: "100%",
          background: "rgba(0, 0, 0, 0.7)",
          borderRadius: "8px",
          boxShadow: 3,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            mb: 2,
            fontWeight: "bold",
            fontSize: "2.5rem",
            textAlign: "left",
          }}
        >
          Vivek Sharma
        </Typography>
        <Typography
          variant="body1"
          component="div"
          sx={{ mb: 2, lineHeight: "1.6", textAlign: "left" }}
        >
          Software developer working with web and mobile technologies. Currently
          developing projects with React, Node.js, and related tools after
          completing three internships and freelance work.
        </Typography>

        <Typography
          variant="body1"
          component="div"
          sx={{ mb: 2, lineHeight: "1.6", textAlign: "left" }}
        >
          I enjoy learning about new technologies and occasionally solving
          programming problems on{" "}
          <a
            href="https://leetcode.com/u/viveksharma14/"
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
              color: "#1E90FF",
              textDecoration: "underline",
              transition: "color 0.3s ease",
            }}
            className="hover:underline hover:text-blue-500"
          >
            LeetCode
          </a>
          . Interested in finding simple solutions to everyday challenges.
        </Typography>

        <Typography
          variant="body1"
          component="div"
          sx={{ lineHeight: "1.6", textAlign: "left" }}
        >
          When not coding, I follow tech trends and enjoy watching TV shows.
          Feel free to reach out if you'd like to connect.
        </Typography>
        <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
          <Tooltip title="LeetCode" arrow>
            <IconButton
              href="https://leetcode.com/u/viveksharma14/"
              target="_blank"
              color="inherit"
              sx={{ mr: 1 }}
            >
              <SiLeetcode />
            </IconButton>
          </Tooltip>
          <Tooltip title="GitHub" arrow>
            <IconButton
              href="https://github.com/Viveksharma121"
              target="_blank"
              color="inherit"
            >
              <GitHub />
            </IconButton>
          </Tooltip>
          <Tooltip title="LinkedIn" arrow>
            <IconButton
              href="https://www.linkedin.com/in/vivek-sharma-776b2424a/"
              target="_blank"
              color="inherit"
            >
              <LinkedIn />
            </IconButton>
          </Tooltip>
          <Tooltip title="Email" arrow>
            <IconButton
              href="mailto:vivekksharma369@gmail.com"
              target="_blank"
              color="inherit"
            >
              <Email />
            </IconButton>
          </Tooltip>
          <Tooltip title="Resume" arrow>
            <IconButton
              target="_blank"
              color="inherit"
              onClick={() => navigate("/resume")}
            >
              <Description />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutMe;
