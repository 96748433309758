import { Download } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";

const ResumePage = () => {
  const resumeUrl =
    "https://drive.google.com/uc?id=1Y7SKFN1jugpCqmnobFdVh5uG_BucX_-S&export=download"; // Google Drive direct download link

  return (
    <div className="relative flex justify-center items-center h-screen p-4 bg-black">
      <iframe
        src="https://drive.google.com/file/d/1Y7SKFN1jugpCqmnobFdVh5uG_BucX_-S/preview" // Google Drive Embed link
        title="Resume"
        width="50%"
        height="100%"
        style={{
          border: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        allow="autoplay"
      />
      <a
        href={resumeUrl}
        download="Vivek_Sharma_Resume_test.pdf" // file name that will be downloaded
        className="absolute top-20 right-4 px-2 py-2 text-white rounded hover:bg-blue-700 transition"
        style={{
          zIndex: 10,
        }}
      >
        <IconButton target="_blank" color="inherit">
          <Download />
        </IconButton>
      </a>
    </div>
  );
};

export default ResumePage;
